import { createRouter, createWebHistory } from 'vue-router'
import NotFoundPage from '../pages/NotFoundPage.vue'
import { PEOPLE_ROUTES } from './people'
import { INSTITUTIONS_ROUTES } from './institutions'
import { SETTINGS_ROUTES } from './settings'
import { LISTS_ROUTES } from './lists'
import { CHANGELOG_ROUTES } from './changelog'
import { EVENTS_ROUTES } from './events/events'
import { RELEASE_NOTES_ROUTES } from './releaseNotes'
import type { PolicyResourceEnum } from '@/graphql/app'
import { usePolicies } from '@/services/policies/usePolicies'
import { until } from '@vueuse/core'

declare module 'vue-router' {
  interface RouteMeta {
    layout?: 'default' | 'wide' | 'wide-constrained'
    title?: string
    requiredAbilities?: {
      resource: PolicyResourceEnum
      action: 'list' | 'view' | 'edit'
    }[]
    relatedEntity?:
      | 'event'
      | 'person'
      | 'institution'
      | 'list'
      | 'form'
      | 'submission'
      | 'email'
      | 'releaseNote'
      | 'room'
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', name: 'home', redirect: { name: 'events' } },

    ...PEOPLE_ROUTES,
    ...INSTITUTIONS_ROUTES,
    ...EVENTS_ROUTES,
    ...LISTS_ROUTES,
    ...CHANGELOG_ROUTES,
    ...SETTINGS_ROUTES,
    ...RELEASE_NOTES_ROUTES,

    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('../pages/ForbiddenPage.vue'),
      meta: { title: 'Forbidden' }
    },
    { path: '/ui', name: 'ui', component: () => import('../pages/UIPage.vue') },
    { path: '/:catchAll(.*)', name: 'notFound', component: NotFoundPage }
  ]
})

router.beforeEach(async (to) => {
  const { loading, can } = usePolicies()

  // Wait until policies are loaded
  await until(loading).toBe(false)

  const requiredAbilities = to.matched.flatMap((matched) => matched.meta.requiredAbilities || [])

  if (
    !requiredAbilities.length ||
    requiredAbilities.every(({ resource, action }) => can(action, resource))
  ) {
    return undefined
  }

  return '/forbidden'
})

export default router
